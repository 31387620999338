<script>
export default {
  created() {
    this.authorLogin()
  },
  methods:{
    authorLogin() {
       const ua = navigator.userAgent.toLowerCase()
       const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
       // const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
       // this.$message(ua)
       // this.$message(ua.match(/wxwork/i))
         console.log('浏览器环境', ua)// 将用户代理头的值转为小写
         // this.$message.success(ua)
       if (ua.indexOf('wxwork') > -1&&isMobile) {
         window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww796d901ba48a35cf&redirect_uri=${encodeURIComponent('https://ys.mpt.cn/authCallback')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
       }
       // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww796d901ba48a35cf&redirect_uri=${encodeURIComponent('https://ys.mpt.cn/authCallback')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
     }
  }
}
</script>
<style lang='less' scoped>
</style>